import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { values } from 'lodash';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { images } from '@utilitywarehouse/partner-ui-assets';
import { useTracking } from '@utilitywarehouse/partner-tracking-react';
import { formatPrice } from 'redux/utils/formatter';
import { price } from 'app/constants/propTypes';
import Tile from 'app/components/modules/OrderSummary/SummarySection/Shared/Tile';
import PriceWithDiscount from 'modules/Shared/PriceWithDiscount';
import BillProtector from 'app/components/modules/OrderSummary/SummarySection/AddOns/BillProtector';
import { FeatureFlagsApi } from 'redux/modules/FeatureFlags/api';
import { FLAGS } from 'redux/modules/FeatureFlags/constants';
import {
  ADD_ON_BILL_PROTECTOR,
  ADD_ON_CASHBACK_CARD,
  addOnDescriptions,
  addOnMap,
  addOnIconMap,
} from 'app/redux/modules/OrderSummary/constants';
import ActionButton from 'app/components/modules/OrderSummary/SummarySection/Shared/ActionButton';
import RestrictedButton from 'app/redux/modules/RemoteSession/v2/RestrictedButton';

import { EligibilityServiceApi } from 'redux/modules/Eligibility/api';

import useEvents from 'app/lib/analytics/AnalyticsProvider/useEvents';
import { formatProductName } from 'app/lib/analytics/AnalyticsProvider/utils';
import { EVENTS } from 'app/lib/analytics/constants';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(2),
  },
  addButton: {
    width: '100%',
  },
  addonOneTimeFee: {
    marginTop: theme.spacing(1),
  },
  addonOneTimeFeeTitle: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const AddOns = ({
  removeService,
  editService,
  addService,
  addOns,
  otherAddOns,
  isEditable,
  isPartner,
}) => {
  const classes = useStyles();
  const analytics = useTracking();
  const coreServicesState = useEvents(EVENTS.SUMMARY_PRODUCT_ADD);
  const isEligibleForCBC = useSelector(EligibilityServiceApi.isEligibleForCBC);
  const protectorsDisabledSelector = FeatureFlagsApi.getFlagSelector(
    FLAGS.INSURANCE_PROTECTORS_DISABLED
  );
  const protectorsDisabled = useSelector(protectorsDisabledSelector);

  if (!isEditable && !values(addOns).some((value) => !!value)) return null;

  const extras = otherAddOns.filter((addOn) => {
    const isBillProtector = addOn.id === 'billProtector';
    const isCashback = addOn.id === 'cashback';

    if (isPartner) {
      if (isBillProtector && protectorsDisabled) {
        return false;
      }
    } else if (isBillProtector) {
      return false;
    }

    if (isCashback && !isEligibleForCBC) {
      return false;
    }

    return true;
  });

  const addOnsEmpty = Object.values(addOns).every(
    (value) => value === undefined
  );
  const extrasEmpty = extras.length === 0;

  return (
    <>
      {(!extrasEmpty || !addOnsEmpty) && (
        <Typography variant="h3" color="primary" className={classes.title}>
          Your add-ons
        </Typography>
      )}
      {addOns.cashback && (
        <Tile
          key={ADD_ON_CASHBACK_CARD}
          icon={images.icons.productIcons.Cashback}
          isEditable={isEditable}
          title="Cashback Card"
          priceSection={
            <PriceWithDiscount price={addOns.cashback.price} responsive />
          }
          actionArea={
            <ActionButton
              label="Remove"
              onClick={() => removeService(ADD_ON_CASHBACK_CARD)}
            />
          }
        >
          <div>
            <Typography>{addOnDescriptions[ADD_ON_CASHBACK_CARD]()}</Typography>
            {addOns.cashback.oneTimeCost && (
              <div className={classes.addonOneTimeFee}>
                <Typography
                  component="span"
                  className={classes.addonOneTimeFeeTitle}
                >
                  Application fee: {formatPrice(addOns.cashback.oneTimeCost)}
                </Typography>{' '}
                <Typography component="span">(first bill only)</Typography>
              </div>
            )}
          </div>
        </Tile>
      )}
      {addOns.billProtector && isPartner && (
        <BillProtector
          isEditable={isEditable}
          key={ADD_ON_BILL_PROTECTOR}
          billProtector={addOns.billProtector}
          removeService={() => removeService(ADD_ON_BILL_PROTECTOR)}
          editService={() => editService(ADD_ON_BILL_PROTECTOR)}
        />
      )}
      {isEditable &&
        extras.map(({ id, price }) => (
          <Tile
            key={id}
            isEditable
            dashed
            fadeTitle
            icon={addOnIconMap[id]}
            title={addOnMap[id]}
            priceSection={
              <PriceWithDiscount price={price} estimated responsive />
            }
            actionArea={
              <RestrictedButton
                label="Add"
                variant="outlined"
                onClick={() => {
                  addService(id);
                  analytics.track(EVENTS.SUMMARY_PRODUCT_ADD, {
                    service_id: formatProductName(id),
                    ...coreServicesState,
                  });
                }}
                className={classes.addButton}
              />
            }
          >
            {<Typography>{addOnDescriptions[id](price)}</Typography>}
          </Tile>
        ))}
    </>
  );
};

AddOns.propTypes = {
  addService: PropTypes.func.isRequired,
  removeService: PropTypes.func.isRequired,
  editService: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isPartner: PropTypes.bool.isRequired,
  addOns: PropTypes.shape({
    cashback: PropTypes.object,
    billProtector: PropTypes.object,
  }),
  otherAddOns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      price: price.isRequired,
    })
  ),
};

export default AddOns;
